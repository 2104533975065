export default {
      staking: {
        1: "0x3C01c6c58E61Ae5fFc0e603abd7ebe5d9d27D97a",
        4: "0x3C01c6c58E61Ae5fFc0e603abd7ebe5d9d27D97a",
        56: "0x3C01c6c58E61Ae5fFc0e603abd7ebe5d9d27D97a",
      },
      gorilla: {
        1: "0x31AF2FA22b71AD9C8d808C8fBd2B0E7262CFe026",
        4: "0x239FC674c459a58bbdb5a1dD6C58d1892C2F33a4",
        56: "0x2a075Bfb43a3EaB718715F5c9b40d8d70FE30D31",
      },
      variable: {
        1: "0xAD93D504631feCA691d0a6EFed72f8344Ee72925",
        4: "0xAD93D504631feCA691d0a6EFed72f8344Ee72925",
        56: "0x3C01c6c58E61Ae5fFc0e603abd7ebe5d9d27D97a",
      },
      standard: {
        1: "0xe559525118f9Ae71e2b21806B15332D17A00EBfF",
        4: "0xe559525118f9Ae71e2b21806B15332D17A00EBfF",
        56: "0x3C01c6c58E61Ae5fFc0e603abd7ebe5d9d27D97a",
    },
  };
  